import moment from 'moment'

const getStatusClass = (status) => {
  let statusParams = {
    error: "statusError",
    integrated: "statusIntegrated",
    scheduled: "statusScheduled",
    processing: "statusProcessing",
    canceled: "statusCanceled",
    deleted: "statusCanceled",
    complete: "statusComplete",
    imported: "statusImported",
    delivered: "statusDelivered",
    dispatched: "statusDispatched",
    holded: "statusHolded",
    presales: "statusIntegrated",
    faturado: "statusFaturado",
    waitingTransfer: "statusScheduled",
    waitingReturn: "statusScheduled",
    picking: "statusPicking",
    "em-remessa": "statusIntegrated"
  };

  return `status ${statusParams[status]}` || "status statusDefault";
}

const fulfilmentsToTable = state => {
  return state.fulfillments.map(o => {
    return {
      Id: o.Id,
      Number: o.Number,
      ExternalNumberId: o.ExternalNumberId,
      TaxOperation: o.TaxOperation,
      PurchaseDate: moment.utc(o.PurchaseDate).format("DD/MM/YYYY HH:mm"),
      EcommerceName: o.EcommerceName,
      Channel: o.Channel,
      WarehouseCode: o.WarehouseCode,
      BplId: o.BplID,
      NFNumber: o.NFNumber,
      ImportedDate: moment.utc(o.ImportedDate).format("DD/MM/YYYY HH:mm"),
      IntegratedDate: o.IntegratedDate ? moment.utc(o.IntegratedDate).format("DD/MM/YYYY HH:mm") : "",
      Status: {
        Name: o.Status.AliasName,
        Class: getStatusClass(o.Status.BahnReference),
        BahnReference: o.Status.BahnReference
      },
      Documents: {
        Number: o.Number,
        DocEntry: o.DocEntry,
        DocEntryNf: o.DocEntryNf,
        DocEntryPicking: o.DocEntryPicking,
        DocEntryCR: o.DocEntryCr,
        DocEntryCP: o.DocEntryCP,
        DocEntryStockTransfer: o.DocEntryStockTransfer
      },
      MarketplaceId: o.MarketplaceId
    }
  });
};

export default {
  fulfilmentsToTable
}

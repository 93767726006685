import Vue from "vue";
import moment from "moment";

Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    });
    return formatter.format(value);
});

Vue.filter('toDate', value => {
    if (value) {
        return moment.utc(String(value)).format('DD/MM/YYYY');
    }
});

Vue.filter('truncateWithEllipsis', (value, maxLength, withDoublePoint = true) => {
  if (!value || typeof value !== 'string') return '';
  if (value.length <= maxLength) return value;
  let ellipsis = withDoublePoint ? '...' : '..';
  return value.slice(0, maxLength - 3) + ellipsis;
});

Vue.filter('nameStatus', value => {
  switch (value) {
    case "Started":
      return "Iniciado";
    case "Error":
      return "Erro";
    case "Success":
      return "Sucesso";
    case "Canceled":
      return "Cancelado";
    case "Delete":
      return "Deletado";
    case "Information":
        return "Informação";
  }
});

Vue.filter('toDateTime', value => {
    if (value) {
        return moment.utc(String(value)).format('DD/MM/YYYY HH:mm:ss');
    }
});

Vue.filter('formatSize', function (size) {
    if (size > 1024 * 1024 * 1024 * 1024) {
      return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
    } else if (size > 1024 * 1024 * 1024) {
      return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
    } else if (size > 1024 * 1024) {
      return (size / 1024 / 1024).toFixed(2) + ' MB'
    } else if (size > 1024) {
      return (size / 1024).toFixed(2) + ' KB'
    }
    return size.toString() + ' B'
});
<template>
  <el-table :data="fulfilmentsToTable" ref="orderTable" :row-class-name="getRowClass"
    @selection-change="handleSelectionChange">
    <template slot="append">
      <el-row v-if="selectedItems.length > 0">
        <el-col class="table-selection-info">
          <span>{{ selectedMessage }}</span>
          <el-link v-if="selectedAllExisting" @click="clearSelected">Limpar seleção.</el-link>
          <el-link v-else @click="selectedAllExisting = true">Selecionar todos os {{ total }} pedidos.</el-link>
        </el-col>
      </el-row>
    </template>
    <el-table-column type="selection" width="50" />
    <el-table-column align="center" label="Id Pedido Platz" prop="Number" min-width="130">
    </el-table-column>
    <el-table-column align="center" label="Pedido Marketplace" prop="ExternalNumberId" min-width="130">
    </el-table-column>
    <el-table-column align="center" label="NF" prop="NFNumber" min-width="100">
    </el-table-column>

    <el-table-column align="center" label="Data de criação" prop="ImportedDate" min-width="130">
    </el-table-column>
    <el-table-column align="center" label="Integração" prop="IntegratedDate" min-width="130">
      <template slot-scope="props">
        <p>
          {{ props.row.IntegratedDate || " Não integrado" }}
        </p>
      </template>
    </el-table-column>
    <el-table-column :min-width="102" align="center" v-if="checkColumn('ShowDocumentColumnInOrderList')"
      label="Documentos">
      <template slot-scope="props">
        <document-status v-if="checkColumn('ShowDocumentColumnInOrderList')" :documents="props.row.Documents"
          :taxOperation="props.row.TaxOperation"
          :document="getDocuments(props.row.TaxOperation, props.row.MarketplaceId)" />
      </template>
    </el-table-column>
    <el-table-column prop="TaxOperation" :min-width="200">
      <template slot-scope="scope">
        <div class="row">
          <p style="word-break: normal; margin-bottom: 1 !important; padding-right: 0 !important"
            class="col-5 text-right text-muted">
            <b>Operação Fiscal:</b>
          </p>
          <div class="col-7">
            <div style="
                width: auto;
                word-break: normal;
                line-height: 15px !important;
                height: auto;
                margin-bottom: 0 !important;
                padding-left: 2px !important;
              " :class="getClassTaxOperation(scope.row.TaxOperation)">
              {{ scope.row.TaxOperation }}
            </div>
          </div>
          <p style="word-break: normal; margin-bottom: 0 !important; padding-right: 0 !important;"
            class="col-5 text-right text-muted">
            <b>Status:</b>
          </p>
          <div class="col-7">
            <div style="
                width: auto;
                word-break: normal;
                line-height: 15px !important;
                height: auto;
                margin-bottom: 0 !important;
                margin-top: 3px !important;
                padding-left: 2px !important;
              " :class="scope.row.Status.Class">
              {{ scope.row.Status.Name }}
            </div>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" label="Ações" min-width="150">
      <div slot-scope="props" class="d-flex justify-content-center">
        <base-button class="like !important btn-link" type="primary" size="sm" icon @click="edit(props.row.Id)">
          <i style="font-size: 25px" class="material-icons">visibility</i>
        </base-button>
        <el-tooltip content="Ver log de integração" effect="light" :open-delay="300" placement="top">
          <base-button class="like !important btn-link" type="primary" size="sm" icon
            @click="showModal(props.row.Id, props.row.Number)">
            <i style="font-size: 25px" class="material-icons">assignment</i>
          </base-button>
        </el-tooltip>
        <el-tooltip content="Reenfileirar pedido" effect="light" :open-delay="300" placement="top">
          <base-button @click="requeue(props.row.EcommerceName, props.row.Number)" v-if="props.row.Status != 'canceled'"
            class="edit btn-link" type="primary" size="sm" icon>
            <i style="font-size: 25px" class="material-icons">loop</i>
          </base-button>
        </el-tooltip>
      </div>
    </el-table-column>
  </el-table>
</template>
<script>
import constants from "@/util/constants";
import { EventBus } from "@/eventBus";
import swal from "sweetalert2";
import DocumentStatus from "./DocumentStatus.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    DocumentStatus,
  },
  props: {
    total: { type: Number },
    fulfillments: {
      type: Array,
      default: function () {
        return [
          {
            Id: "",
            TaxOperation: "",
            ExternalNumberId: "",
            CreatedOn: "",
            OrderId: "",
            InvoiceAccessKey: "",
            Status: "",
          },
        ];
      },
    },
  },
  data() {
    return {
      selectedItems: [],
      selectedAllExisting: false,
      multipleSelection: [],
      dialogVisible: false,
      configurations: [],
    };
  },
  computed: {
    ...mapState("fulfillment", ["fulfillmentConfigurations"]),
    ...mapGetters("fulfillment", ["fulfilmentsToTable"]),
    selectedMessage() {
      return this.selectedAllExisting
        ? `Todos os ${this.total} pedidos foram selecionados.`
        : `${this.selectedItems.length} pedidos selecionados.`;
    },
  },
  methods: {
    ...mapActions("configurations", ["getConfigurationByKeys"]),
    ...mapActions("fulfillment", ["requeueSelected"]),
    getRowClass({ rowIndex }) {
      return rowIndex % 2 === 0 ? "row-light" : "row-dark";
    },
    showModal(id, number) {
      this.$router.push({
        name: "LogsOrderFulfillment",
        query: { id: id, number: number },
      });
    },
    getClassTaxOperation(status) {
      let tax = constants.TaxOperation.find((a) => a.description === status);
      return tax ? tax.class : "status statusDefault";
    },
    edit(id) {
      this.$router.push({ name: "FulfillmentDetail", params: { id: id } });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    clearSelected() {
      this.$refs.orderTable.clearSelection();
      this.selectedItems = [];
      EventBus.$emit("selectionChangeFulFillment", this.selectedItems.length);
    },
    handleSelectionChange(val) {
      this.selectedItems = val.map((x) => x.Number);
      this.selectedAllExisting = false;
      EventBus.$emit("selectionChangeFulFillment", this.selectedItems.length);
    },
    checkColumn(key) {
      let configuration = this.configurations.find((a) => a.Key === key);
      return configuration ? configuration.Value === "true" : true;
    },
    async getConfigurations() {
      let query = {
        ecommerceName: this.$store.state.ecommerce.Name,
        keys: [constants.Configurations.ShowDocumentColumnInOrderList],
      };
      await this.getConfigurationByKeys(query).then((response) => {
        this.configurations = response.data;
      });
    },
    requeue(ecommerceName, id) {
      swal({
        title: "Tem certeza?",
        text: "Quer mesmo reenfileirar este pedido?",
        type: "warning",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {

          let orderData = {
            ecommerceName: this.$store.state.ecommerce.Name,
            orders: [id],
            selectAllItens: false,
          };


          this.requeueSelected(orderData)
            .then(async () => {
              this.$showSuccess("Pedido(s) reenfileirado(s) com sucesso");
              this.$emit("filter");
            })
            .catch(() => this.$showError("Ocorreu um erro ao reenfileirar os pedidos"));
        }
      });
    },
    getDocuments(taxOperation, marketplaceId) {
      return this.fulfillmentConfigurations.find((a) => a.TaxOperationDescription === taxOperation && a.MarketplaceId === marketplaceId);
    },
  },
};
</script>
<style>
.my-bg-header {
  background-color: #27293d !important;
}

.el-table .cell {
  text-overflow: initial !important;
  font-size: 10pt !important;
  line-height: 15px !important;
}

.row-light {
  background-color: #d40e0e; /* Cinza mais claro */
}

.row-dark {
  background-color: #e0e0e0; /* Cinza mais escuro */
}

.align-left {
  text-align: left;
}

.el-table th>.cell {
  font-size: 8pt !important;
}
</style>

<template>
  <div>
    <button class="custom-btn-save m-1" @click="openModal">
      Adicionar SKU
    </button>
    <el-dialog :visible.sync="dialogVisible" width="75%" :before-close="handleClose">
      <h4>Adicionar Sku</h4>
      <div class="d-flex">
        <base-input class="m-1" label="Nome" :required="true" v-model="sku.name" />
        <base-input class="m-1" label="EAN" :required="scheduleSyncProductsActive" v-model="sku.ean" />
        <base-input class="m-1" label="SKU no SAP" :required="true" v-model="sku.skuSap" />
        <base-input class="m-1" label="Id no Ecommerce" :required="true">
          <input v-model="sku.eCommerceId" type="number" class="form-control"
            aria-describedby="addon-right addon-left" />
        </base-input>
        <div class="flex-fill ml-3" align="left">
          <h5>Ativo</h5>
          <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
            v-model="sku.active" :sync="true" />
        </div>
      </div>
      <el-divider></el-divider>
      <div class="flex-fill card-body">
        <h4>Midia</h4>
        <div class="d-flex">
          <base-input class="m-1 col-4" label="URL" v-model="url" />
          <base-input class="m-1 col-4" label="Nome" v-model="name" />
          <base-input class="m-1" label="Tipo">
            <el-select class="select-primary" placeholder="Tipo da Mídia" v-model="type">
              <el-option class="select-primary" v-for="(attr, index) in types" :key="index" :label="attr.label"
                :value="attr.value"></el-option>
            </el-select>
          </base-input>
          <base-button @click="addImage" round icon type="primary" class="mt-4 ml-3">
            <i class="tim-icons icon-simple-add"></i>
          </base-button>
        </div>
        <div class="d-flex">
          <ProductImageItem v-for="img in sku.imagens" :key="img.index" :image="img" @evtRemoveImage="removeImage" />
        </div>
      </div>
      <el-divider></el-divider>
      <div class="flex-fill card-body">
        <h4>Atributos da SKU</h4>
        <div class="d-flex">
          <base-input class="m-1" label="Atributo">
            <el-select class="select-primary" placeholder="Atributo" v-model="specification.attributeId">
              <el-option class="select-primary" v-for="(attr, index) in attributes" :key="index"
                :label="attr.Description" :value="attr.Id"></el-option>
            </el-select>
          </base-input>
          <base-input class="m-1" label="Valor do Atributo" v-model="specification.value" />
          <base-button @click="addSpecification" round icon type="primary" class="mt-4 ml-3">
            <i class="tim-icons icon-simple-add"></i>
          </base-button>
        </div>
        <div>
          <el-table :data="sku.specifications" style="width: 100%">
            <el-table-column prop="index" label="#" :width="90"></el-table-column>
            <el-table-column prop="attributeName" label="Atributo"></el-table-column>
            <el-table-column label="Descrição">
              <div slot-scope="props">
                {{ getDescriptionAttribute(props.row.attributeName) }}
              </div>
            </el-table-column>
            <el-table-column label="Grupo">
              <div slot-scope="props">
                {{ getGroupAttribute(props.row.attributeName) }}
              </div>
            </el-table-column>
            <el-table-column prop="value" label="Valor do Atributo"></el-table-column>
            <el-table-column :min-width="135" align="left" label="Ações">
              <div slot-scope="props">
                <base-button class="edit btn-link" type="danger" size="sm" icon @click="remove(props.row.index)">
                  <i class="material-icons">delete_outline</i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancelar</el-button>
        <el-button type="primary" @click="addSku">Adicionar</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ProductImageItem from "@/pages/Product/components/ProductImageItem";
import { mapState, mapActions } from "vuex";
import { EventBus } from "@/eventBus";

export default {
  components: {
    ProductImageItem,
  },
  data() {
    return {
      dialogVisible: false,
      sku: {
        name: null,
        eCommerceId: null,
        ean: null,
        skuSap: null,
        active: true,
        imagens: [],
        specifications: []
      },
      url: null,
      name: null,
      type: null,
      specification: {
        attributeId: null,
        attributeName: null,
        value: ""
      },
      types: [{ label: 'Capa', value: 1 }, { label: 'Imagem', value: 2 }, { label: 'Video', value: 3 }],
      video: null,
    };
  },
  computed: {
    ...mapState("product", ["productCurrent", "attributes", "scheduleSyncProductsActive"]),
  },
  methods: {
    ...mapActions("product", ["getAttributes"]),
    remove(index) {
      this.sku.specifications = this.sku.specifications.filter(
        (attr) => attr.index != index
      );
      this.reIndexAttributes();
    },
    reIndexAttributes() {
      this.sku.specifications.forEach((attr, index) => {
        attr.index = index + 1;
      });
    },
    handleClose() {
      this.sku = {
        name: null,
        eCommerceId: null,
        ean: null,
        skuSap: null,
        active: true,
        imagens: [],
        specifications: []
      };

      this.url = null;
      this.name = null;
      this.type = null;
      this.eCommerceId = null;
      this.specification.attributeId = null,
        this.specification.attributeName = null,
        this.specification.value = null;

      this.dialogVisible = false;
      EventBus.$emit("clearTouched");
    },
    openModal() {
      this.getAttributes({ type: 'Sku', status: 'true' });
      this.dialogVisible = true;
    },
    validate() {
      let errors = "";
      let valid = true;

      if (!this.sku.name) {
        errors += "- <b>Nome</b> obrigatório";
        valid = false;
      }
      if (!this.sku.eCommerceId) {
        errors += "<br>- <b>Id</b> no Ecommerce obrigatório";
        valid = false;
      } else if (!/^\d+$/.test(this.sku.eCommerceId)) {
        errors += "<br>- O campo <b>ID do E-commerce</b> não permite caracteres especiais. Por favor, ajuste a informação para prosseguir.";
        valid = false;
      }

      if (!this.sku.skuSap) {
        errors += "<br>- <b>SKU no SAP</b> obrigatório";
        valid = false;
      }
      if (!this.sku.ean && this.scheduleSyncProductsActive) {
        errors += "<br>- <b>EAN</b> obrigatório";
        valid = false;
      }

      if (this.scheduleSyncProductsActive) {
        if (!this.sku.specifications.some(x => x.attributeName == 'Weight'))
          errors += "<br>- Necessário informar o atributo <b>Peso</b> no SKU";
        if (!this.sku.specifications.some(x => x.attributeName == 'Height'))
          errors += "<br>- Necessário informar o atributo <b>Altura</b> no SKU";
        if (!this.sku.specifications.some(x => x.attributeName == 'Length'))
          errors += "<br>- Necessário informar o atributo <b>Largura</b> no SKU";
        if (!this.sku.specifications.some(x => x.attributeName == 'Width'))
          errors += "<br>- Necessário informar o atributo <b>Comprimento</b> no SKU";
      }

      valid = !errors.length > 0;

      if (!valid)
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          duration: 3000,
          message: errors
        });

      return valid;
    },
    addSku() {
      if (!this.validate())
        return;

      this.$emit("evtAddSku", this.sku);
      this.sku = {
        name: null,
        eCommerceId: null,
        ean: null,
        skuSap: null,
        active: true,
        imagens: [],
        specifications: []
      };

      this.url = null;
      this.name = null;
      this.type = null;
      this.specification.attributeId = null,
        this.specification.attributeName = null,
        this.specification.value = null;

      this.handleClose();
    },
    addImage() {
      if (!this.url || !this.name || !this.type) {
        this.$showError("Preencha todos os campos");
        return;
      }

      if (this.sku.imagens.length == 10) {
        this.$showError("Permitido incluir no máximo 10 midias.");
        return;
      }

      this.sku.imagens = this.createListImageWithIndex(
        this.sku.imagens,
        { url: this.url, nome: this.name, type: this.type }
      );

      this.image = null;
      this.url = null;
      this.name = null;
      this.type = null;
    },
    addSpecification() {

      if (!this.specification.attributeId) {
        this.$showError("Selecione o atributo");
        return;
      }

      if (!this.specification.value) {
        this.$showError("Informe o valor do atributo");
        return;
      }

      if (this.sku.specifications.some(a => a.attributeId === this.specification.attributeId)) {
        this.$showError("Já possui um atributo deste tipo na lista.");
        return;
      }

      let attr = this.attributes.find(a => a.Id === this.specification.attributeId);

      this.specification.attributeName = attr.Name;

      this.sku.specifications = this.createListSpecificationWithIndex(
        this.sku.specifications,
        this.specification
      );

      this.specification = { attributeName: null, attributeId: null, value: "" };
    },
    reIndexSpecifications() {
      this.sku.specifications.forEach((spc, index) => {
        spc.index = index + 1;
      });
    },
    reIndexImages() {
      this.sku.imagens.forEach((img, index) => {
        img.index = index + 1;
      });
    },
    removeImage(index) {
      this.sku.imagens = this.sku.imagens.filter((img) => img.index != index);
      this.reIndexImages();
    },
    removeSpecification(index) {
      this.sku.specifications = this.sku.specifications.filter((spc) => spc.index != index);
      this.reIndexSpecifications();
    },
    createListImageWithIndex(array, object) {
      let index = array.length + 1;
      object = object instanceof Object ? object : { url: object };
      array.push({ ...object, index: index });

      return array;
    },
    createListSpecificationWithIndex(array, object) {
      let index = array.length + 1;
      object = object instanceof Object ? object : { object };
      array.push({ ...object, index: index });

      return array;
    },
    getDescriptionAttribute(name) {
      let attr = this.attributes.find(a => a.Name === name);
      return attr ? attr.Description : " --- ";
    },
    getGroupAttribute(name) {
      let attr = this.attributes.find(a => a.Name === name);
      return attr ? attr.AttributeGroupName : " --- ";
    }
  },
  mounted() {
    this.$validator.reset();
  }
};
</script>
<style>
.card-body {
  margin: 10px;
  border-width: 0.5px;
  border-radius: 5px;
}

.el-dialog {
  background: #181926;
}
</style>

<template>
  <div class="row" style="float: right">
    <span style="margin: 0 10pt">
      <base-input>
        <el-date-picker type="date" format="dd/MM/yyyy" placeholder="De" v-model="filters.From"></el-date-picker>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-date-picker type="date" format="dd/MM/yyyy" placeholder="Até" v-model="filters.To"></el-date-picker>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-select class="select-primary mb-3 pagination-select" v-model="filters.TaxOperation"
          placeholder="Operação Fiscal">
          <el-option class="select-primary" v-for="(item, index) in taxOperations" :key="index" :label="item.description"
            :value="item.value"></el-option>
        </el-select>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Id Pedido Platz" v-model="filters.OrderId" @keyup.enter.native="filter">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-input type="search" prefix-icon="el-icon-search" placeholder="N° da Nota" v-model="filters.InvoiceNumber" @keyup.enter.native="filter">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-select class="select-primary mb-3 pagination-select" v-model="filters.Status" placeholder="Status" multiple>
          <el-option class="select-primary" v-for="(item, index) in statuses" :key="index" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <button class="custom-btn-filter" @click="filter">Filtrar</button>
    </span>
    <span class="mr-2">
      <el-button :class="existItems
        ? 'custom-btn-filter'
        : 'custom-btn-filter-disabled'
        " @click="requeue" :disabled="!existItems">
        Reenfileirar
      </el-button>
    </span>
    <span style="margin: 0 10pt">
      <button class="custom-btn-filter" @click="clearFilter">
        Limpar filtro
      </button>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-dropdown @command="handleCommand">
          <el-button class="custom-btn-filter">
            Exportar<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="exportAll">Todos</el-dropdown-item>
            <el-dropdown-item command="exportErrors">Somente com erro</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </base-input>
    </span>
    <span style="margin: 0 10pt">
      <base-input>
        <el-dropdown>
          <el-button class="custom-btn-filter">
            Ações<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span @click="$router.push({ name: 'SyncStatus', query: { orderType: 2 } })">Atualizar Status</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </base-input>
    </span>
  </div>
</template>
<script>
import { EventBus } from "@/eventBus";
import { mapActions } from 'vuex';
import moment from "moment";
import constants from '@/util/constants';

export default {
  data() {
    return {
      filters: {
        From: null,
        To: null,
        TaxOperation: null,
        OrderId: null,
        InvoiceNumber: null,
        Status: null,
      },
      existItems: false,
      taxOperations: constants.TaxOperation,
      statuses: []
    };
  },
  methods: {
    ...mapActions("fulfillment", ["exportFulfillments", "getStatus"]),
    ...mapActions("orders", ["exportOrderError"]),
    filter() {
      this.$emit("filter");
    },
    value() {
      if (this.filters.From)
        this.filters.From = moment(this.filters.From)
          .format('YYYY-MM-DD HH:mm:ss');

      if (this.filters.To)
        this.filters.To = moment(this.filters.To)
          .hour(23)
          .minute(59)
          .second(59)
          .format('YYYY-MM-DD HH:mm:ss');

      return this.filters;
    },
    requeue() {
      EventBus.$emit("requeueFulFillment");
    },
    async exportAll() {
      await this.exportFulfillments(this.filters).then(response => {
        this.downloadFile("Fulfillment.xlsx", response.data);
      });
    },
    async exportErrors() {
      let filter = this.filters;
      filter = Object.assign({ orderTypes: [constants.OrderType.Fulfillment] }, filter);

      await this.exportOrderError(filter)
        .then(response => {
          this.downloadFile("pedidos-com-erro.xlsx", response.data);
        });
    },
    async handleCommand(command) {
      switch (command) {
        case "exportAll":
          await this.exportAll();
          break;
        case "exportErrors":
          await this.exportErrors();
          break;
      }
    },
    downloadFile(file, fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      return;
    },
    clearFilter() {
      this.filters = {
        From: null,
        To: null,
        TaxOperation: null,
        OrderId: null,
        InvoiceNumber: null,
        Status: null,
      };
      this.$emit("filter");
    }
  },
  async mounted() {
    await this.getStatus().then((response) => {
      response.data
        .forEach(sts =>
          this.statuses.push(
            {
              name: sts.AliasName,
              value: sts.BahnReference,
              id: sts.ID
            }
          ));
    });

    EventBus.$on("selectionChangeFulFillment", (items) => items > 0 ? this.existItems = true : this.existItems = false);
  }
};
</script>
<template>
    <transition name="fade" mode="out-in">
        <card card-body-classes="table-full-width">
            <h4>Editar SKU</h4>
            <div class="d-flex ml-2">
                <base-input class="m-1" label="Nome" :required="true" v-model="sku.Name" />
                <base-input class="m-1" label="EAN" :required="scheduleSyncProductsActive" v-model="sku.EAN" />
                <base-input class="m-1" label="SKU no SAP" :required="true" v-model="sku.SkuSAP" />
                <base-input class="m-1" label="Id no Ecommerce" :required="true">
                    <input v-model="sku.ECommerceId" type="number" class="form-control"
                        aria-describedby="addon-right addon-left" />
                </base-input>
                <div class="flex-fill ml-3" align="left">
                    <h5>Ativo</h5>
                    <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                        v-model="sku.Active" :sync="true" />
                </div>
            </div>
            <el-divider></el-divider>
            <div class="flex-fill card-body">
                <h4>Conexão com Ecommerce</h4>
                <div class="row">
                    <div class="col-2 flex-fill">
                        <p class="m-1"><i class="mr-2 el-icon-box"></i><strong>Estoque</strong></p>
                        <p class="m-1" :style="statusJobStock.class"><i :class="statusJobStock.icon"></i>{{
                            statusJobStock.text }}</p>
                        <p class="m-1"><i style="font-size: 14pt;" class="mr-2 el-icon-upload"></i>{{
                            lastLogStock.UpdateDate |
                            toDateTime }}</p>
                    </div>
                    <div class="col-2 flex-fill">
                        <p class="m-1">$ <strong>Preço</strong></p>
                        <p class="m-1" :style="statusJobPrice.class"><i :class="statusJobPrice.icon"></i>{{
                            statusJobPrice.text }}</p>
                        <p class="m-1"><i style="font-size: 14pt;" class="mr-2 el-icon-upload"></i>{{
                            lastLogPrice.UpdateDate |
                            toDateTime }}</p>
                    </div>
                </div>
            </div>
            <el-divider></el-divider>
            <div class="flex-fill card-body">
                <h4>Midia</h4>
                <div class="d-flex">
                    <base-input class="m-1 col-4" label="URL" v-model="url" />
                    <base-input class="m-1 col-4" label="Nome" v-model="name" />
                    <base-input class="m-1" label="Tipo">
                        <el-select class="select-primary" placeholder="Tipo da Mídia" v-model="type">
                            <el-option class="select-primary" v-for="(attr, index) in types" :key="index"
                                :label="attr.label" :value="attr.value"></el-option>
                        </el-select>
                    </base-input>
                    <base-button @click="addImage" round icon type="primary" class="mt-4 ml-3">
                        <i class="tim-icons icon-simple-add"></i>
                    </base-button>
                </div>
                <div class="d-flex">
                    <ProductImageItem v-for="img in sku.Imagens" :key="img.index"
                        :image="{ index: img.index, type: img.Type, url: img.Url, nome: img.Nome }"
                        @evtRemoveImage="removeImage" />
                </div>
            </div>
            <el-divider></el-divider>
            <div class="flex-fill card-body">
                <h4>Atributos da SKU</h4>
                <div class="d-flex">
                    <base-input class="m-1" label="Atributo">
                        <el-select class="select-primary" placeholder="Atributo" v-model="specification.AttributeId">
                            <el-option class="select-primary" v-for="(attr, index) in attributes" :key="index"
                                :label="attr.Name" :value="attr.Id"></el-option>
                        </el-select>
                    </base-input>
                    <base-input class="m-1" label="Valor do Atributo" v-model="specification.Value" />
                    <base-button @click="addSpecification" round icon type="primary" class="mt-4 ml-3">
                        <i class="tim-icons icon-simple-add"></i>
                    </base-button>
                </div>
                <div>
                    <el-table :data="sku.Specifications" style="width: 100%">
                        <el-table-column label="Atributo">
                            <div slot-scope="props">
                                {{ getAttribute(props.row.AttributeId) }}
                            </div>
                        </el-table-column>
                        <el-table-column align="center" label="Descrição">
                            <div slot-scope="props">
                                {{ getDescriptionAttribute(props.row.AttributeId) }}
                            </div>
                        </el-table-column>
                        <el-table-column align="center" label="Grupo">
                            <div slot-scope="props">
                                {{ getGroupAttribute(props.row.AttributeId) }}
                            </div>
                        </el-table-column>
                        <el-table-column align="center" prop="Value" label="Valor do Atributo"></el-table-column>
                        <el-table-column align="center" :min-width="135" label="Ações">
                            <div slot-scope="props">
                                <base-button class="edit btn-link" type="danger" size="sm" icon
                                    @click="removeSpecification(props.row.index)">
                                    <i class="material-icons">delete_outline</i>
                                </base-button>
                            </div>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div slot="footer" class="col-12 d-flex justify-content-end flex-wrap">
                <div class="col-4">
                    <button class="custom-btn-add m-1" @click="$router.go(-1)">Voltar</button>
                    <button class="custom-btn-save m-1" @click="save">Salvar</button>
                </div>
            </div>
        </card>
    </transition>
</template>
<script>
import ProductImageItem from "@/pages/Product/components/ProductImageItem";
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        ProductImageItem
    },
    data() {
        return {
            sku: {
                Name: null,
                ECommerceId: null,
                EAN: null,
                SkuSAP: null,
                Active: true,
                Imagens: [],
                Specifications: []
            },
            url: null,
            name: null,
            type: null,
            specification: {
                AttributeId: null,
                AttributeName: null,
                Value: ""
            },
            types: [{ label: 'Capa', value: 1 }, { label: 'Imagem', value: 2 }, { label: 'Video', value: 3 }],
            video: null,
            lastLogStock: null,
            lastLogPrice: null
        }
    },
    computed: {
        ...mapState("product", ["skuCurrent", "attributes", "scheduleSyncProductsActive", "productCurrent"]),
        statusJobStock() {
            switch (this.productCurrent.SyncStock) {
                case 2:
                    return { text: "Processando", class: "color : #e39500", icon: "el-icon-loading" };
                case 3:
                    return { text: "Atualizado", class: "color : #66aa00", icon: "el-icon-circle-check" };
                case 4:
                    return { text: "Erro", class: "color : #eb2d2d", icon: "el-icon-circle-close" };
                default:
                    return { text: "Pendente", class: "color : #9f9f9f", icon: "el-icon-time" };
            }
        },
        statusJobPrice() {
            switch (this.productCurrent.SyncPrice) {
                case 2:
                    return { text: "Processando", class: "color : #e39500", icon: "el-icon-loading" };
                case 3:
                    return { text: "Atualizado", class: "color : #66aa00", icon: "el-icon-circle-check" };
                case 4:
                    return { text: "Erro", class: "color : #eb2d2d", icon: "el-icon-circle-close" };
                default:
                    return { text: "Pendente", class: "color : #9f9f9f", icon: "el-icon-time" };
            }
        }
    },
    methods: {
        ...mapActions("product", ["getSkuById", "updateSku", "getAttributes"]),
        ...mapActions("logOrder", ["getLastLogStockBySku", "getLastLogPriceBySku"]),
        validate() {
            let errors = "";
            let valid = true;

            if (!this.sku.Name) {
                errors += "- <b>Nome</b> obrigatório";
                valid = false;
            }
            if (!this.sku.ECommerceId) {
                errors += "<br>- <b>Id</b> no Ecommerce obrigatório";
                valid = false;
            }
            if (!this.sku.SkuSAP) {
                errors += "<br>- <b>SKU no SAP</b> obrigatório";
                valid = false;
            }
            if (!this.sku.EAN && this.scheduleSyncProductsActive) {
                errors += "<br>- <b>EAN</b> obrigatório";
                valid = false;
            }

            if (!/^\d+$/.test(this.sku.ECommerceId)) {
                errors += "<br>- O campo <b>ID do E-commerce</b> não permite caracteres especiais. Por favor, ajuste a informação para prosseguir.";
                valid = false;
            }

            if (!valid)
                this.$message({
                    dangerouslyUseHTMLString: true,
                    type: 'error',
                    duration: 3000,
                    message: errors
                });

            return valid;
        },
        save() {
            if (!this.validate())
                return;

            this.updateSku(this.sku).then(res => {
                this.$showSuccess("SKU atualizada com sucesso");
                this.$router.go(-1);
            }).catch(error => { this.$showError(error.response.data) });
        },
        getAttribute(attributeId) {
            let attr = this.attributes.find(a => a.Id === attributeId);
            return attr.Name;
        },
        addImage() {
            if (!this.url || !this.name || !this.type) {
                this.$showError("Preencha todos os campos");
                return;
            }

            if (this.sku.Imagens.length == 10) {
                this.$showError("Permitido incluir no máximo 10 midias.");
                return;
            }

            this.sku.Imagens = this.createListImageWithIndex(
                this.sku.Imagens,
                { Url: this.url, Nome: this.name, Type: this.type }
            );

            this.url = null;
            this.name = null;
            this.type = null;
        },
        addSpecification() {

            if (!this.specification.AttributeId) {
                this.$showError("Selecione o atributo");
                return;
            }

            if (!this.specification.Value) {
                this.$showError("Informe o valor do atributo");
                return;
            }

            if (this.sku.Specifications.some(a => a.AttributeId === this.specification.AttributeId)) {
                this.$showError("Já possui um atributo deste tipo na lista.");
                return;
            }

            let attr = this.attributes.find(a => a.Id === this.specification.AttributeId);

            this.specification.AttributeName = attr.Name;

            this.sku.Specifications = this.createListSpecificationWithIndex(
                this.sku.Specifications,
                this.specification
            );

            this.specification = { AttributeName: null, AttributeId: null, Value: "" };
        },
        reIndexSpecifications() {
            this.sku.Specifications.forEach((spc, index) => {
                spc.index = index + 1;
            });
        },
        reIndexImages() {
            this.sku.Imagens.forEach((img, index) => {
                img.index = index + 1;
            });
        },
        removeImage(index) {
            this.sku.Imagens = this.sku.Imagens.filter((img) => img.index != index);

            this.reIndexImages();
        },
        removeSpecification(index) {
            this.sku.Specifications = this.sku.Specifications.filter((spc) => spc.index != index);
            this.reIndexSpecifications();
        },
        createListImageWithIndex(array, object) {
            let index = array.length + 1;
            object = object instanceof Object ? object : { Url: object };
            array.push({ ...object, Index: index });

            return array;
        },
        createListSpecificationWithIndex(array, object) {
            let index = array.length + 1;
            object = object instanceof Object ? object : { object };
            array.push({ ...object, index: index });

            return array;
        },
        getDescriptionAttribute(id) {
            let attr = this.attributes.find(a => a.Id === id);
            return attr ? attr.Description : " --- ";
        },
        getGroupAttribute(id) {
            let attr = this.attributes.find(a => a.Id === id);
            return attr ? attr.AttributeGroupName : " --- ";
        }
    },
    mounted() {
        let id = this.$route.params.id;
        if (!id) this.$router.go(-1);

        this.getAttributes({ type: 'Sku', status: 'true' });
        this.getSkuById(id).then(async res => {
            this.sku = res.data;
            this.reIndexImages();
            this.reIndexSpecifications();
            this.lastLogStock = await this.getLastLogStockBySku(this.sku.SkuSAP);
            this.lastLogPrice = await this.getLastLogPriceBySku(this.sku.SkuSAP);
        });
    },
}
</script>

<template>
  <section>
    <base-list
      :totalRecords="totalRecords"
      @changePage="search"
      ref="baseList"
      title="Pedidos Fulfillment"
    >
      <FullfillmentListFilter ref="filter" @filter="search" />
      <FullfillmentListTable ref="table" @filter="search" :total="totalRecords" />
    </base-list>
    <router-view></router-view>
  </section>
</template>
<script>
import BaseList from "@/components/BaseList";
import { EventBus } from "@/eventBus";
import { mapActions, mapState } from "vuex";
import swal from "sweetalert2";
import FullfillmentListFilter from "../components/FullfillmentListFilter.vue";
import FullfillmentListTable from "../components/FullfillmentListTable.vue";

export default {
  data() {
    return {
      totalRecords: 0,
    };
  },
  components: {
    ...mapState(["ecommerce"]),
    BaseList,
    FullfillmentListFilter,
    FullfillmentListTable,
  },
  methods: {
    ...mapActions("fulfillment", ["getFulfillments", "requeueSelected", "getAllFulfillmentConfigurations"]),
    async search() {
      let pagination = this.$refs.baseList.value();
      let filter = this.$refs.filter.value();

      await this.getFulfillments({ ...pagination, ...filter }).then((res) => {
        this.totalRecords = res.data.Total;
      });
    },
    async requeueAll() {
      swal({
        title: "Tem certeza?",
        text: "Deseja reenfileirar o envio dos pedidos selecionados?",
        type: "warning",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          let filter = this.$refs.filter.value();
          let orderData = this.$refs.table.selectedAllExisting
            ? {
                ecommerceName: this.$store.state.ecommerce.Name,
                from: filter.From,
                to: filter.To,
                taxOperation: filter.TaxOperation,
                status: filter.Status,
                orderId: filter.OrderId,
                orders: [],
                invoiceNumber: filter.InvoiceNumber,
                selectAllItens: this.$refs.table.selectedAllExisting,
              }
            : {
                ecommerceName: this.$store.state.ecommerce.Name,
                orders: [...this.$refs.table.selectedItems],
                selectAllItens: this.$refs.table.selectedAllExisting,
              };

          await this.requeueSelected(orderData)
            .then(async () => {
              this.$showSuccess("Pedido(s) reenfileirado(s) com sucesso");
              await this.search();
            })
            .catch(() => this.$showError("Ocorreu um erro ao reenfileirar os pedidos"));
        }
      });
    },
  },
  async mounted() {
    await this.search();
    await this.getAllFulfillmentConfigurations();
    EventBus.$on("requeueFulFillment", () => this.requeueAll());
  },
};
</script>
<style scoped></style>

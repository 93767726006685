<template>
    <div class="d-flex justify-content-center">
        <el-tooltip v-if="documents.DocEntry" content="Pedido de Venda" effect="light" :open-delay="300"
            placement="top">
            <el-dropdown trigger="click">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                    role="img" class="iconify iconify--mdi" width="20px" height="20" preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24" @click="showModal('2')">
                    <path :fill="getStatus('DocEntry')"
                        d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z">
                    </path>
                </svg>
            </el-dropdown>
        </el-tooltip>
        <el-tooltip v-if="document.GeneratePicking" content="Picking" effect="light" :open-delay="300" placement="top">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                role="img" class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24" @click="showModal('7')">
                <path :fill="getStatus('DocEntryPicking')"
                    d="M18 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5m1.5-9H17V12h4.46L19.5 9.5M6 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5M20 8l3 4v5h-2c0 1.66-1.34 3-3 3s-3-1.34-3-3H9c0 1.66-1.34 3-3 3s-3-1.34-3-3H1V6c0-1.11.89-2 2-2h14v4h3M3 6v9h.76c.55-.61 1.35-1 2.24-1c.89 0 1.69.39 2.24 1H15V6H3Z">
                </path>
            </svg>
        </el-tooltip>
        <el-tooltip v-if="document.GenerateSaleInvoice" content="Nota Fiscal de Saída" effect="light" :open-delay="300"
            placement="top">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                role="img" class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24" @click="showModal('6')">
                <path :fill="getStatus('DocEntryNf')"
                    d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6H6m0 2h7v5h5v11H6V4m2 8v2h8v-2H8m0 4v2h5v-2H8Z">
                </path>
            </svg>
        </el-tooltip>
        <el-tooltip v-if="document.GenerateReturnInvoice" content="Dev. Nota Fiscal de Saída" effect="light"
            :open-delay="300" placement="top">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-receipt-cutoff" viewBox="0 0 16 16" @click="showModal('15')">
                <path :fill="getStatus('DocEntryNf')"
                    d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5M11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z" />
                <path :fill="getStatus('DocEntryNf')"
                    d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118z" />
            </svg>
        </el-tooltip>
        <el-tooltip v-if="document.GenerateStockTransfer" content="Transferência de Estoque" effect="light"
            :open-delay="300" placement="top">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                role="img" class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24" @click="showModal('14')">
                <g fill="none">
                    <path :fill="getStatus('DocEntryStockTransfer')"
                        d="M8.56 11.9a1.5 1.5 0 0 1 0 2.12l-.974.976H16a1.5 1.5 0 0 1 0 3H7.586l.975.974a1.5 1.5 0 1 1-2.122 2.122l-3.535-3.536a1.5 1.5 0 0 1 0-2.121l3.535-3.536a1.5 1.5 0 0 1 2.122 0Zm6.88-9a1.5 1.5 0 0 1 2.007-.104l.114.103l3.535 3.536a1.5 1.5 0 0 1 .103 2.007l-.103.114l-3.535 3.536a1.5 1.5 0 0 1-2.225-2.008l.103-.114l.975-.974H8a1.5 1.5 0 0 1-.144-2.994L8 5.996h8.414l-.975-.975a1.5 1.5 0 0 1 0-2.122Z" />
                </g>
            </svg>
        </el-tooltip>
        <el-tooltip content="Contas a Receber" effect="light" :open-delay="300" placement="top"
            v-if="document.GenerateIncomingPayments">
            <el-dropdown trigger="click">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                    role="img" class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24" @click="showModal('5')">
                    <path :fill="getStatus('DocEntryCR')"
                        d="M7 15h2c0 1.08 1.37 2 3 2s3-.92 3-2c0-1.1-1.04-1.5-3.24-2.03C9.64 12.44 7 11.78 7 9c0-1.79 1.47-3.31 3.5-3.82V3h3v2.18C15.53 5.69 17 7.21 17 9h-2c0-1.08-1.37-2-3-2s-3 .92-3 2c0 1.1 1.04 1.5 3.24 2.03C14.36 11.56 17 12.22 17 15c0 1.79-1.47 3.31-3.5 3.82V21h-3v-2.18C8.47 18.31 7 16.79 7 15Z">
                    </path>
                </svg>
            </el-dropdown>
        </el-tooltip>
        <el-tooltip v-if="document.GenerateVendorPayments" content="Contas a Pagar" effect="light" :open-delay="300"
            placement="top">
            <el-dropdown trigger="click">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                    role="img" class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24" @click="showModal('16')">
                    <path :fill="getStatus('DocEntryCP')"
                        d="M7 15h2c0 1.08 1.37 2 3 2s3-.92 3-2c0-1.1-1.04-1.5-3.24-2.03C9.64 12.44 7 11.78 7 9c0-1.79 1.47-3.31 3.5-3.82V3h3v2.18C15.53 5.69 17 7.21 17 9h-2c0-1.08-1.37-2-3-2s-3 .92-3 2c0 1.1 1.04 1.5 3.24 2.03C14.36 11.56 17 12.22 17 15c0 1.79-1.47 3.31-3.5 3.82V21h-3v-2.18C8.47 18.31 7 16.79 7 15Z">
                    </path>
                </svg>
            </el-dropdown>
        </el-tooltip>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        documents: {
            type: Array | String | Number | Boolean | Object,
            default: () => ({
                "DocEntry": null,
                "DocEntryPicking": null,
                "DocEntryNf": null,
                "DocEntryStockTransfer": null,
                "DocEntryCR": null,
                "DocEntryCP": null,
            })
        },
        document: {
            type: Array | String | Number | Boolean | Object,
            default: () => ({
                "GenerateIncomingPayments": false,
                "GeneratePicking": false,
                "GenerateReturnInvoice": false,
                "GenerateSaleInvoice": false,
                "GenerateStockTransfer": false,
                "GenerateVendorPayments": false
            })
        }

    },
    computed: {
        ...mapState(['ecommerce', "realm"])
    },
    methods: {
        getStatus(prop) {
            switch (prop) {
                case 'DocEntry':
                    return this.documents.DocEntry !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryCR':
                    return this.documents.DocEntryCR !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryCP':
                    return this.documents.DocEntryCP !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryNf':
                    return this.documents.DocEntryNf !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryPicking':
                    return this.documents.DocEntryPicking !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryStockTransfer':
                    return this.documents.DocEntryStockTransfer !== 0 ? "#00ADB7" : "#888888";
            }
        },
        showModal(prop) {
            if (this.documents && this.documents.Number) {
                const number = this.documents.Number.toString();
                this.$router.push({
                    name: 'DocumentFulfillmentJson',
                    params: { number, type: prop },
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #F5F7FA !important;
    color: #1d253b !important;
}
</style>

import Vue from "vue";

const getFulfillments = async ({ rootState, commit }, payload) => {
  let params = Object.assign({ ...payload }, { ecommerceName: rootState.ecommerce.Name });


  const queryString = Object.entries(params)
    .filter(([key, value]) => value !== null)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(item => `${key}=${encodeURIComponent(item)}`).join('&');
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return await Vue.prototype.$bahngleis.get(`Fulfillment?${queryString}`).then(response => {
    commit('SET_FULFILLMENTS', response.data.Fulfillments);
    return Promise.resolve(response);
  }).catch(error => {
    return Promise.reject(error);
  });
}

const getFulfillmentById = async ({ commit }, { id, displayRestrictedData }) => {
  return await Vue.prototype.$bahngleis.get(
    `Fulfillment/${id}?displayRestrictedData=${displayRestrictedData}`).then(response => {
      commit("SET_FULFILLMENT", response.data);
      return response;
    });
}

const exportFulfillments = async ({ rootState, commit }, payload) => {
  let params = Object.assign({ ...payload }, { ecommerceName: rootState.ecommerce.Name });
  return await Vue.prototype.$bahngleis.get(`Fulfillment/export`,
    {
      responseType: 'blob',
      params: params
    });
}

const getFulfillmentConfigurations = async ({ rootState, commit }, params) => {
  let query = "";
  if (params.type && params.name)
    query = `&${params.type}=${params.name}`;

  return await Vue.prototype.$bahngleis.get(`FulfillmentConfiguration/GetPaginated?EcommerceName=${rootState.ecommerce.Name}&CurrentPage=${params.currentPage}&PerPage=${params.perPage}${query}`)
    .then(response => {
      commit('SET_FULFILLMENTCONFIGURATIONS', response.data);
      return response;
    });
}

const getLogFulfillmentConfiguration = async ({ rootState, commit }, id) => {
  return await Vue.prototype.$bahngleis.get(`LogAction/getlogsofentity?identity=${id}&entity=FulfillmentConfiguration`)
    .then(response => {
      commit('SET_LOGS', response.data);
      return response;
    });
}

const createFulfillmentConfiguration = ({ rootState, commit }, payload) => {
  payload.EcommerceName = rootState.ecommerce.Name;
  return Vue.prototype.$bahngleis
    .post("FulfillmentConfiguration", payload)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const updateFulfillmentConfiguration = ({ commit }, payload) => {
  return Vue.prototype.$bahngleis
    .put(`FulfillmentConfiguration/${payload.Id}`, payload)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const removeFulfillmentConfiguration = async ({ }, id) => {
  return await Vue.prototype.$bahngleis
    .delete(`FulfillmentConfiguration/${id}`)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const getFulfillmentLogs = ({ }, id) => {
  return Vue.prototype.$bahngleis.get(`/LogAction/getlogsofentity?identity=${id}&entity=Order`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error.response.data));
}

const getStatus = async ({ rootState }) => {
  return await Vue.prototype.$bahngleis.get(`Status/GetByWithFilter?ecommerceName=${rootState.ecommerce.Name}`);
}

const requeueSelected = async ({ }, payload) => {
  return await Vue.prototype.$bahngleis.post(`Fulfillment/RequeueOrdersListFulfillment`, payload)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const getAllFulfillmentConfigurations = async ({ rootState, commit }) => {
  return await Vue.prototype.$bahngleis.get(`FulfillmentConfiguration/GetAll?EcommerceName=${rootState.ecommerce.Name}`)
    .then(response => {
      commit('SET_FULFILLMENTCONFIGURATIONS', response.data);
      return response;
    });
}

export default {
  getFulfillmentConfigurations,
  getLogFulfillmentConfiguration,
  createFulfillmentConfiguration,
  updateFulfillmentConfiguration,
  removeFulfillmentConfiguration,
  getFulfillments,
  getFulfillmentById,
  exportFulfillments,
  getFulfillmentLogs,
  getStatus,
  requeueSelected,
  getAllFulfillmentConfigurations
}

<template>
  <div>
    <div class="pl-2 mt-2 row">
      <span class="mr-2">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome do Produto"
            @keyup.enter.native="filter" v-model="filters.name">
          </el-input>
        </base-input>
      </span>
      <span class="mr-2">
        <base-input>
          <el-input type="number" prefix-icon="el-icon-search" placeholder="Id E-commerce" @keyup.enter.native="filter"
            v-model="filters.id"></el-input>
        </base-input>
      </span>
      <span class="mr-2">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="SKU ERP" @keyup.enter.native="filter"
            v-model="filters.skuSAP">
          </el-input>
        </base-input>
      </span>
      <span class="mr-2">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="SKU Id" @keyup.enter.native="filter"
            v-model="filters.skuId">
          </el-input>
        </base-input>
      </span>
      <span class="mr-2">
        <base-input>
          <el-select class="select-primary mb-3 pagination-select" v-model="filters.status" placeholder="Status">
            <el-option class="select-primary" v-for="item in statuses" :key="item.name" :label="item.name"
              :value="item.value"></el-option>
          </el-select>
        </base-input>
      </span>
      <span class="mr-2">
        <base-input>
          <el-select class="select-primary mb-3 pagination-select" v-model="filters.variation"
            placeholder="Tipo de Produto">
            <el-option class="select-primary" v-for="item in types" :key="item.name" :label="item.name"
              :value="item.value"></el-option>
          </el-select>
        </base-input>
      </span>
      <span class="mr-2">
        <button class="custom-btn-filter" @click="filter">Filtrar</button>
      </span>
      <span class="mr-2">
        <button class="custom-btn-filter" @click="clearFilter">Limpar filtro</button>
      </span>
      <span class="mr-2">
        <button class="custom-btn-add" @click="addNew">Novo</button>
      </span>
      <span class="mr-2">
        <base-input>
          <el-dropdown @command="handleCommand">
            <el-button class="custom-btn-filter">
              Ações<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <file-upload post-action="/upload/post" extensions="xlsx" accept=".xlsx" :multiple="false"
                  :size="1024 * 1024 * 10" v-model="files" @input-file="inputFile('products', $event)" ref="upload">
                  Importar Produtos
                </file-upload>
              </el-dropdown-item>
              <el-dropdown-item divided>
                <file-upload post-action="/upload/post" extensions="xlsx" accept=".xlsx" :multiple="false"
                  name="mapping" :size="1024 * 1024 * 10" v-model="mappingFile"
                  @input-file="inputFile('mapping', $event)" ref="upload">
                  Importar Mapeamento
                </file-upload>
              </el-dropdown-item>
              <el-dropdown-item command="evtExport" divided> Exportar </el-dropdown-item>
              <el-dropdown-item command="evtExportMapping">
                Exportar Mapeamento
              </el-dropdown-item>
              <el-dropdown-item v-if="['shopify', 'anymarket'].includes(selectedPlatform.toLowerCase())">
                <span @click="exportProductEcommerce">Exportar Produtos Ecommerce</span>
              </el-dropdown-item>
              <el-dropdown-item divided>
                <span @click="template">Template de Produtos</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <a class="link-download" :href="templateImportMapping" download>Template de Mapeamento</a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </base-input>
      </span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import FileUpload from "vue-upload-component";
import ExportProductModal from "../ExportProductModal.vue";
import constants from '@/util/constants';

export default {
  components: {
    FileUpload,
    ExportProductModal,
  },
  data() {
    return {
      filters: {
        name: null,
        id: null,

        status: null,
        variation: null,
        skuSAP: null,
        skuId: null,
      },
      mappingFile: [],
      uploadType: null,
      files: [],
      templateImportMapping: require("@/assets/template-xls/Template-Importar-Mapeamento.xlsx"),
      statuses: [
        {
          name: "Todos",
          value: null,
        },
        {
          name: "Ativo",
          value: true,
        },
        {
          name: "Inativo",
          value: false,
        },
      ],
      types: [
        {
          name: "Todos",
          value: null,
        },
        {
          name: "Com Variação",
          value: true,
        },
        {
          name: "Simples",
          value: false,
        },
      ],
      selectedPlatform: "",
    };
  },
  computed: {
    ...mapState(["ecommerce"]),
    ...mapState("product", ["productFilter"]),
    showField() {
      if (this.ecommerce && this.ecommerce.Platform) {
        return (
          this.getPlatformNames(this.ecommerce.Platform).toLowerCase() === "anymarket" ||
          this.getPlatformNames(this.ecommerce.Platform).toLowerCase() === "shopify"
        );
      }

      return false;
    },
  },
  methods: {
    ...mapActions("product", ["importProducts", "getTemplate", "importProductsMapping"]),
    ...mapMutations("product", ["SET_PRODUCT_FILTER"]),
    addNew() {
      this.$router.push({ name: "ProductRegister" });
    },
    filter() {
      this.$emit("filter");
    },
    exportProductEcommerce() {
      this.$router.push({
        name: "ExportProductModal",
      });
    },
    handleCommand(command) {
      switch (command) {
        case "evtExport":
          this.exportProduct();
          break;
        case "evtExportMapping":
          this.exportProductMapping();
          break;
        default:
          break;
      }
    },
    exportProduct() {
      this.$emit("evtExport");
    },
    exportProductMapping() {
      this.$emit("evtExportMapping");
    },

    triggerFileUpload(type) {
      this.uploadType = type;
    },

    inputFile: async function (action, newFile) {
      if (!newFile) return;

      let formData = new FormData();
      formData.append("file", newFile.file);

      switch (action) {
        case "products":
          this.importProducts(formData).then((res) => {
            this.$showSuccess("Arquivo importado com sucesso.");
            this.filter();
          }).catch((err) => {
            this.$showError(err.response.data);
          });
          break;
        case "mapping":
          await this.importProductsMapping(formData).then((res) => {
            this.$showSuccess("Arquivo importado com sucesso.");
            this.filter();
          }).catch((err) => {
            this.$showError(err.response.data);
          });
          break;
      }
    },
    downloadFile(file, fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      return;
    },
    async template() {
      await this.getTemplate().then((res) => {
        this.downloadFile("Produtos Importação.xlsx", res.data);
      });
    },
    clearFilter() {
      this.filters = {
        name: null,
        id: null,
        status: null,
        variation: null,
        skuSAP: null,
        skuId: null,
      };
      this.$emit("filter");
    },
    value() {
      return this.filters;
    },
    getPlatformNames(key) {
      return constants.EcommercePlatForm.find((a) => a.Value === key).Label;
    },

  },
  watch: {
    filters: {
      handler(newVal) {
        this.SET_PRODUCT_FILTER(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.productFilter) this.filters = this.productFilter;

    this.selectedPlatform = this.getPlatformNames(this.ecommerce.Platform).toLowerCase();
  },
};
</script>
<style>
.link-download {
  color: black;
}

.link-download:hover {
  color: #66b1ff;
}
</style>

import Vue from "vue";
import moment from "moment";

const getProductsMapping = async ({ rootState, commit }, query) => {

    let path = `ProductSync/mapping?ecommerceName=${rootState.ecommerce.Name}&perPage=${query.perPage}&currentPage=${query.currentPage}`;

    if (query.value && query.type)
        path += `&${query.type}=${query.value}`;

    return await Vue.prototype.$bahngleis.get(path).then(response => {
        commit('SET_PRODUCTS_MAPPING', response.data.Items);
        return response.data;
    });
}

const updateProductMapping = async ({ commit }, payload) => {
    return await Vue.prototype.$bahngleis
        .put(`ProductSync/${payload.Id}`, payload, {
            headers: {
                "Content-Type": "application/json",
            },
        });
}

const saveProduct = async ({ rootState }, payload) => {
    payload = Object.assign(payload, { ecommerceName: rootState.ecommerce.Name });
    return await Vue.prototype.$bahngleis
        .post("product", payload);
}

const sendProductEcommerce = async ({ rootState }) => {
    let path = `StartSync/syncProducts?ecommerceName=${rootState.ecommerce.Name}`
    return await Vue.prototype.$bahngleis
        .get(path);
}

const updateProduct = async ({ rootState }, payload) => {
    payload = Object.assign(payload, { ecommerceName: rootState.ecommerce.Name });
    return await Vue.prototype.$bahngleis
        .put(`product/${payload.id}`, payload);
}

const deleteProduct = ({ }, id) => {
    return Vue.prototype.$bahngleis
        .delete(`product/${id}`);
}

const deleteProductMapping = async ({ rootState, commit }, sku) => {
    return await Vue.prototype.$bahngleis.delete(`ProductSync/${rootState.ecommerce.Name}/Delete/${sku}`)
}

const exportProductEcommerce = async ({ rootState }, payload) => {
    payload.EcommerceName = rootState.ecommerce.Name;
    return Vue.prototype.$bahngleis
        .post("ProductSync/ExportProductEcommerce", payload)
        .then(res => { return Promise.resolve(res) })
        .catch(err => { return Promise.reject(err.response.data) });
}

const getProducts = async ({ rootState, commit }, params) => {
    params = Object.assign(params, { ecommerceName: rootState.ecommerce.Name });
    return await Vue.prototype.$bahngleis.get('product', { params: params }).then(response => {
        commit('SET_PRODUCTS', response.data.Products);
        return response;
    });
}

const getProductById = async ({ commit }, id) => {
    return await Vue.prototype.$bahngleis.get(`product/${id}`).then(response => {
        commit('SET_PRODUCT_CURRENT', response.data);
        return response;
    }).catch(error => { return error.response.data });
}

const getSkus = async ({ commit }, params) => {
    return await Vue.prototype.$bahngleisWithoutLoading.get('product/skus', { params: params }).then(response => {
        return response;
    });
}

const getBrands = async ({ rootState }) => {
    return await Vue.prototype.$bahngleis.get(`brand/GetByEcommerceName?ecommerceName=${rootState.ecommerce.Name}`).then(response => {
        return response.data;
    });
}

const getAttributes = async ({ rootState, commit }, params) => {
    params = Object.assign(params, { ecommerceName: rootState.ecommerce.Name });
    return await Vue.prototype.$bahngleis.get('attribute', { params }).then(response => {
        commit('SET_ATTRIBUTES', response.data.Attributes);
        return Promise.resolve(response);
    });
}

const saveAttribute = ({ rootState }, payload) => {
    payload = Object.assign(payload, { ecommerceName: rootState.ecommerce.Name });
    return Vue.prototype.$bahngleis.post('attribute', payload);
}

const removeAttribute = ({ }, id) => {
    if (!id)
        return;

    return Vue.prototype.$bahngleis.delete(`attribute/${id}`);
}

const removeSpecification = ({ }, id) => {
    if (!id)
        return Promise.resolve();

    return Vue.prototype.$bahngleis.delete(`product/specification/${id}`);
}

const getAttributeById = ({ commit }, id) => {
    return Vue.prototype.$bahngleis.get(`attribute/${id}`).then(resp => {
        commit('SET_ATTRIBUTE_CURRENT', resp.data);
        return Promise.resolve(resp);
    });
}

const updateAttribute = ({ }, payload) => {
    return Vue.prototype.$bahngleis.put(`attribute/${payload.Id}`, payload);
}

const getSkuById = ({ commit }, id) => {
    return Vue.prototype.$bahngleis.get(`Sku/${id}`).then(resp => {
        return Promise.resolve(resp);
    });
}

const updateSku = ({ rootState }, payload) => {
    return Vue.prototype.$bahngleis.put(`sku/${payload.Id}?ecommerceName=${rootState.ecommerce.Name}`, payload);
}

const deleteSku = ({ rootState }, id) => {
    return Vue.prototype.$bahngleis.delete(`sku/${id}?ecommerceName=${rootState.ecommerce.Name}`);
}

const importProducts = async ({ rootState }, payload) => {
    return await Vue.prototype.$bahngleis.post(`product/sendfile/${rootState.ecommerce.Name}`, payload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

const importProductsMapping = ({ rootState }, payload) => {
    return Vue.prototype.$bahngleis.post(`ProductSync/import-plan?ecommerceName=${rootState.ecommerce.Name}`, payload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }).then(resp => {
        return Promise.resolve(resp);
    });
}

const getTemplate = async ({ rootState }) => {
    return await Vue.prototype.$bahngleis.get(`product/getfile/${rootState.ecommerce.Name}`, {
        responseType: "blob",
    });
}

const exportProducts = async ({ rootState }, params) => {
    return await Vue.prototype.$bahngleis.get(`product/ExportProducts?ecommerceName=${rootState.ecommerce.Name}`, {
        responseType: "blob",
        params: params
    });
}

const exportProductsMapping = async ({ rootState }, params) => {
    return await Vue.prototype.$bahngleis.get(`product/ExportProductsMapping?ecommerceName=${rootState.ecommerce.Name}`, {
        responseType: "blob",
        params: params
    });
}

const getProductIntegration = async ({ commit, rootState }, params) => {
    return await Vue.prototype.$bahngleis.get(`product/ConnectionsLog/${rootState.ecommerce.Name}`, { params: params }).then(res => {
        commit("SET_PRODUCT_INTEGRATION_LOG", res.data);
        return Promise.resolve(res);
    }).catch(err => { return Promise.reject(err.response.data) });
}

const exportProductIntegration = async ({ rootState }, params) => {
    return await Vue.prototype.$bahngleis.get(`product/ExportLogConnections/${rootState.ecommerce.Name}`,
        {
            responseType: "blob",
            params: params
        });
}

const getFilesLog = ({ commit }, params) => {
    return Vue.prototype.$bahngleis.get('fileimport/getfiles', { params: params }).then(res => {
        commit('SET_FILES_LOG', res.data);
        Promise.resolve(res);
    }).catch(err => {
        return Promise.reject(err.response.data);
    });
}

const getFileLogDetail = ({ commit }, params) => {
    return Vue.prototype.$bahngleis.get('fileimport/getlines', { params: params }).then(res => {
        commit('SET_FILE_LOG', res.data);
        return Promise.resolve(res);
    }).catch(err => {
        return Promise.reject(err.response.data);
    });
}

const getGroups = ({ }, params) => {
    return Vue.prototype.$bahngleisWithoutLoading.get('attributegroup', { params: params }).then(res => {
        return Promise.resolve(res);
    }).catch(err => {
        return Promise.reject(err.response.data);
    });
}

const saveGroup = ({ }, payload) => {
    return Vue.prototype.$bahngleis.post('attributegroup', payload);
}

const removeGroup = ({ }, id) => {
    return Vue.prototype.$bahngleisWithoutLoading.delete(`attributegroup/${id}`)
        .then((res) => { return Promise.resolve(res) })
        .catch((error) => { return Promise.reject(error.response.data) });
}

const getLogs = ({ commit }, id) => {
    return Vue.prototype.$bahngleis.get(`/LogAction/getlogsofentity?identity=${id}&entity=ProductSync`)
        .then((response) => {
            commit('SET_LOGS', response.data);
            Promise.resolve(response)
        })
        .catch((error) => Promise.reject(error.response.data));
}

const getSkusLongTail = async ({ commit }, params) => {
    return await Vue.prototype.$bahngleisWithoutLoading.get('product/skusLongTail', { params: params }).then(response => {
        return response;
    });
}

export default {
    getProductsMapping,
    updateProductMapping,
    saveProduct,
    deleteProductMapping,
    getProducts,
    getProductById,
    getBrands,
    getAttributes,
    saveAttribute,
    removeAttribute,
    getAttributeById,
    updateAttribute,
    getSkuById,
    updateSku,
    updateProduct,
    deleteProduct,
    exportProducts,
    exportProductsMapping,
    importProducts,
    getProductIntegration,
    exportProductIntegration,
    getFilesLog,
    getFileLogDetail,
    getGroups,
    saveGroup,
    removeGroup,
    getTemplate,
    removeSpecification,
    deleteSku,
    getSkus,
    exportProductEcommerce,
    sendProductEcommerce,
    getLogs,
    importProductsMapping,
    getSkusLongTail
}
